<template>
	<div style="display: flex;">
		<leftMenu style="margin-top: 16px;"></leftMenu>
		<div class="content">
			<router-view ></router-view>
		</div>
	</div>
</template>

<script>
import leftMenu from '../../components/common/leftMenu.vue'
export default{
	components:{
		leftMenu
	}
}
</script>
<style scoped>
	.content {
		width: calc(100% - 308px);
		min-height: 564px;
		background: #FFFFFF;
		margin: 0 auto;
		margin-top: 16px;
	}
</style>

<style lang="less" scoped>
.content {
	box-shadow: @srims-primary-box-shadow;
	margin-bottom: 20px;
	border-radius: 4px;
}
</style>



<template>
	<div style="" class="core">
		<a-menu  style="min-height: calc(100vh - 310px);overflow-y: scroll;width: 272px;padding: 0 16px;" v-model="current" mode="inline" :defaultOpenKeys="['1','2','3','4']">
			<a-sub-menu key="1">
				<span  slot="title" class="submenu-title-wrapper">
					<a-icon type="audit" />
					用户中心
				</span>
				<a-menu-item key="basicInformation" >
					<router-link style="" :to="{path:'/core/basicInformation'}">
						基本资料
					</router-link>
				</a-menu-item>
				<a-menu-item key="loginLog">
					<router-link style="" :to="{path:'/core/loginLog'}">
						登录日志
					</router-link>
				</a-menu-item>
				<a-menu-item key="myGoodFaithBahavior">
					<router-link style="" :to="{path:'/core/myGoodFaithBahavior'}">
						诚信行为
					</router-link>
				</a-menu-item>
			</a-sub-menu>
			<a-sub-menu key="2">
				<span slot="title" class="submenu-title-wrapper">
					<a-icon type="layout" />
					模板资源
				</span>
				<a-menu-item key="myTemplate">
					<router-link style="" :to="{path:'/core/myTemplate'}">
						我的模板
					</router-link>
	
				</a-menu-item>
				<a-menu-item key="templateCenter">
					<router-link style="" :to="{path:'/core/templateCenter'}">
						模板中心
					</router-link>
	
				</a-menu-item>
				
				
			</a-sub-menu>
			<!-- <a-sub-menu key="3">
				<span slot="title" class="submenu-title-wrapper">
					<a-icon type="carry-out" />
					我的预约
				</span>
				<a-menu-item key="peopleCenterCageLog">
					<router-link style="" :to="{path:'/core/cageLog'}">
						笼位预约
					</router-link>
	
				</a-menu-item>
			</a-sub-menu> -->
			<a-sub-menu key="4">
				<span slot="title" class="submenu-title-wrapper">
					<a-icon type="team" />
					课题组管理
				</span>
				<a-menu-item key="myResearchTeam">
					<router-link style="" :to="{path:'/core/myResearchTeam'}">
						我的课题组
					</router-link>
				</a-menu-item>
			</a-sub-menu>
		</a-menu>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				current: ['basicInformation'],
			}
		},
		watch: { //动态监听路由变化 -以便动态更改导航背景色事件效果等
			'$route'(to, from) {
				// 对路由变化作出响应...
				this.resetMenus()
			}
		},
		created() {
			this.resetMenus()
		},
		methods:{
			resetMenus() {
				/* let urlLength = this.$route.path.split('/').length
				let current = this.$route.path.split('/')[urlLength-1]
				this.current = [current.toString()] */
				this.current = [this.$route.name]
			},
		}
	}
</script>

<style lang="less" scoped>
.submenu-title-wrapper {
	min-width: 180px;
	display: block;
	text-align: left;
	white-space: nowrap;
}
.core {
	/deep/ .ant-menu-item-selected {
		background: @srims-primary-color !important;
		color: white !important;
		border-radius: 4px !important;
		&::after {
			border-color: transparent !important;
		}
	}
	/deep/ .ant-menu-item {
		height: 40px !important;
		display: flex;
		border-radius: 2px !important;
		
		align-items: center;
	}
	/deep/ .ant-menu-item-active {
		color:  @srims-primary-color !important;
	}
	/deep/ .ant-menu-item-selected a {
		color: white !important;
	}

	/deep/ ::-webkit-scrollbar {
		display: none;
		/* Chrome Safari */
	}

	/deep/ .ant-upload {
		background-color: white !important;
		border: 0px !important;
	}

	/deep/ .ant-menu-submenu {
		// z-index: 1001 !important;
		padding: 3px 0;
	}
	/deep/ .ant-menu-submenu-title{
		padding: 0;
		padding-right: 132px;
		// width: 272px !important;
	}
	/deep/ .ant-menu-root {
		box-shadow: @srims-primary-box-shadow;
	}
	/deep/ .ant-menu-submenu-selected {
		.ant-menu-submenu-title {
			color:  @srims-primary-color;
		}
	}
}
	
</style>